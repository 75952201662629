import { PDFDownloadLink } from '@react-pdf/renderer';
import { graphql, PageRendererProps } from 'gatsby';
import { Link, navigate } from 'gatsby-plugin-locale';
import { mergePrismicPreviewData } from 'gatsby-source-prismic';
import React, { FC, useMemo } from 'react';
import { defineMessages, useIntl } from 'gatsby-plugin-locale';
import { useAuthContext } from '../hooks/useAuthContext';
import { useMedia } from '../hooks/useMedia';
import { get } from '../utils';
import { isBrowser } from '../utils';
import { GatsbyPageProps } from '../declarations';
import { Layout } from '../components/Layout';
import { SEO } from '../components/seo';
import { ToolTemplatePdf } from './ToolTemplatePdf';
import {
	ToolBySlugQuery,
	ToolBySlugQuery_prismicTool_data_body,
	ToolBySlugQuery_prismicTool_data_body_PrismicToolBodyHeaderOnly,
	ToolBySlugQuery_prismicTool_data_body_PrismicToolBodySection,
	ToolBySlugQuery_prismicTool_data_body_PrismicToolBodySectionWOHeader,
} from './__generated__/ToolBySlugQuery';

const messages = defineMessages({
	tool: {
		id: 'tool.tool',
		defaultMessage: 'Tool',
	},
	backToToolkit: {
		id: 'tool.backToToolkit',
		defaultMessage: 'Terug naar toolkit',
	},
	material: {
		id: 'tool.material',
		defaultMessage: 'Materiaal',
	},
	downloadInstructions: {
		id: 'tool.downloadInstructions',
		defaultMessage: 'Download deze instructies als PDF',
	},
	downloadTemplate: {
		id: 'tool.downloadTemplate',
		defaultMessage: 'Download template',
	},
	coaching: {
		id: 'tool.coaching',
		defaultMessage:
			'Op zoek naar een procesbegeleider voor een workshop, intervisie of verandertraject? Of hulp nodig bij een methodiek, draaiboek of architectuur van een workshop?',
	},
	contactUs: {
		id: 'tool.contactUs',
		defaultMessage: 'Contacteer ons',
	},
	helpNeeded: {
		id: 'tool.helpNeeded',
		defaultMessage: 'Hulp nodig?',
	},
	template: {
		id: 'tool.template',
		defaultMessage: 'Template',
	},
});

export type TranslatedToolMessages = { [key in keyof typeof messages]: string };

const ToolTemplate: FC<{ data: ToolBySlugQuery } & GatsbyPageProps & PageRendererProps> = ({
	data: staticData,
	location,
}) => {
	const mergedData: ToolBySlugQuery = useMemo(() => {
		// If we're not in a browser (i.e. we're in SSR) or preview data has not been
		// set, use the non-preview static data.
		if (typeof window === 'undefined' || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

		return mergePrismicPreviewData({
			staticData,
			previewData: window.__PRISMIC_PREVIEW_DATA__,
		}) as any;
	}, [staticData]);

	const { loggedIn, authLoading } = useAuthContext();

	const hasAccess = loggedIn || (location.state as any)?.isPreview;

	const breakpoint = useMedia({ xs: 0, md: 768 }, 'xs');
	const { formatMessage: f } = useIntl();
	const data = get(mergedData, 'prismicTool', 'data');

	const { description, body, requiredMaterial, template, short_description } = data;

	const name = get(data.name, 'text');

	const renderButtons = useMemo(() => {
		if (!isBrowser) return null;
		return (
			<>
				<PDFDownloadLink
					className="block py-3 px-4 rounded text-sm shadow-md md:text-md cursor-pointer font-medium font-display bg-primary text-white text-center mb-4"
					document={
						<ToolTemplatePdf
							tool={data}
							messages={Object.keys(messages).reduce<TranslatedToolMessages>(
								(prev, messageKey) => ({
									...prev,
									[messageKey]: f(messages[messageKey as keyof typeof messages]),
								}),
								{} as any
							)}
						/>
					}
					fileName={`tool_${name.toLowerCase().replace(' ', '_')}.pdf`}>
					{({ loading, error }) =>
						loading ? 'Bezig met laden' : f(messages.downloadInstructions)
					}
				</PDFDownloadLink>

				{template.raw?.name && (
					<a
						href={template.url as string}
						download={template.raw?.name}
						target="_blank"
						rel="noopener noreferrer"
						className="block py-3 px-4 rounded text-sm shadow-md md:text-md cursor-pointer font-display bg-secondary text-white text-center font-medium">
						{f(messages.downloadTemplate)}
					</a>
				)}

				<div className="mt-10 px-4 pt-6 border-t-2 border-gray-200">
					<div className="text-secondary text-lg font-medium font-display">
						{f(messages.helpNeeded)}
					</div>
					<div className="text-secondary-600 mb-3 text-sm mt-1">
						{f(messages.coaching)}
					</div>

					<a
						href="https://intensio.be/contact"
						rel="noopener noreferrer"
						target="_blank"
						className="inline-block rounded text-sm md:text-md cursor-pointer font-display text-primary text-center font-medium">
						{f(messages.contactUs)}
					</a>
				</div>
			</>
		);
	}, []);

	if ((!data?.is_available_for_free && !isBrowser) || authLoading) {
		return null;
	}

	if (!hasAccess && !data?.is_available_for_free) {
		navigate('/auth/login', { replace: true });

		return null;
	}

	return (
		<Layout>
			<SEO
				title={`Tool: ${name}`}
				description={get(short_description, 'text')}
				tool={{ name }}
				pathname={location.pathname}
			/>
			<div id="tool" className="container mx-auto my-12 xl:my-24 relative px-10 md:px-0">
				<div className="mb-10">
					<Link to="/" className="text-bold text-gray-500 flex items-center p-1">
						<i className="bx bx-arrow-back mr-3"></i> {f(messages.backToToolkit)}
					</Link>

					<h1 className="text-secondary text-2xl font-black md:text-3xl mb-6">
						{f(messages.tool)}: {name}
					</h1>
					<p
						className="text-secondary-600 text-md leading-relaxed max-w-2xl"
						dangerouslySetInnerHTML={{ __html: description?.html as string }}
					/>
				</div>

				<div className="flex flex-col md:flex-row">
					<div className="w-full md:w-4/6 order-1 md:order-0">
						<hr />

						{body.map((section, index) => {
							if (!section) {
								return null;
							}

							if (isSection(section)) {
								return (
									<section
										key={section.primary?.section_header?.text as string}
										className="tool-section mt-10">
										<h2 className="text-secondary text-xl font-black md:text-1xl mb-4">
											{section.primary?.section_header?.text}
										</h2>
										<p
											className="text-secondary-600 text-md body"
											dangerouslySetInnerHTML={{
												__html: section.primary?.section_content
													?.html as string,
											}}
										/>
									</section>
								);
							}

							if (isSectionWithoutHeader(section)) {
								return (
									<section key={index} className="tool-section mt-6">
										<p
											className="text-secondary-600 text-md body"
											dangerouslySetInnerHTML={{
												__html: section.primary?.section_content
													?.html as string,
											}}
										/>
									</section>
								);
							}

							if (isHeaderOnlySection(section)) {
								return (
									<section
										key={section.primary?.section_header?.text as string}
										className="tool-section mt-10">
										<h2 className="text-secondary text-xl font-black md:text-1xl mb-4">
											{section.primary?.section_header?.text}
										</h2>
									</section>
								);
							}

							return null;
						})}

						{template?.raw?.kind === 'image' && template?.url && (
							<section className="tool-section mt-10">
								<h2 className="text-secondary text-xl font-black md:text-1xl mb-4">
									{f(messages.template)}
								</h2>
								<img src={template?.url} alt="" />
							</section>
						)}
					</div>

					<div className="w-full md:w-2/6 flex justify-center md:pl-12 order-0 md:order-1 mb-12 lg:mb-0">
						<div className="w-full lg:p-6">
							{!!requiredMaterial?.html?.length && (
								<div className="bg-secondary-100 rounded-lg p-6 md:mb-8">
									<div className="text-secondary text-lg font-medium font-display">
										{f(messages.material)}
									</div>
									<p
										className="text-secondary-600"
										dangerouslySetInnerHTML={{
											__html: requiredMaterial.html,
										}}></p>
								</div>
							)}

							{breakpoint === 'md' && renderButtons}
						</div>
					</div>
				</div>

				{breakpoint === 'xs' && (
					<div>
						<hr className="mt-8" />
						<div className="mt-8">{renderButtons}</div>
					</div>
				)}
			</div>
		</Layout>
	);
};

export const isSection = (
	s: ToolBySlugQuery_prismicTool_data_body
): s is ToolBySlugQuery_prismicTool_data_body_PrismicToolBodySection => s.slice_type === 'section';

export const isSectionWithoutHeader = (
	s: ToolBySlugQuery_prismicTool_data_body
): s is ToolBySlugQuery_prismicTool_data_body_PrismicToolBodySectionWOHeader =>
	s.slice_type === 'section_w_o_header';
export const isHeaderOnlySection = (
	s: ToolBySlugQuery_prismicTool_data_body
): s is ToolBySlugQuery_prismicTool_data_body_PrismicToolBodyHeaderOnly =>
	s.slice_type === 'header_only';

export default ToolTemplate;

export const pageQuery = graphql`
	query ToolBySlugQuery($uid: String!, $locale: String!) {
		prismicTool(uid: { eq: $uid }, lang: { eq: $locale }) {
			uid
			data {
				name {
					text
				}
				short_description {
					text
				}
				categories {
					category {
						id
						uid
					}
				}
				description {
					html
				}
				requiredMaterial {
					html
				}
				template {
					url
					raw
				}
				is_available_for_free
				link_to_file {
					raw
					url
				}
				body {
					... on PrismicToolBodySection {
						id
						primary {
							section_header {
								text
							}
							section_content {
								html
							}
						}
						slice_type
					}
					... on PrismicToolBodySectionWOHeader {
						id
						primary {
							section_content {
								html
							}
						}
						slice_type
					}
					... on PrismicToolBodyHeaderOnly {
						id
						primary {
							section_header {
								text
							}
						}
						slice_type
					}
				}
			}
		}
	}
`;
