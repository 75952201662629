import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { FC } from 'react';
import logo from '../assets/images/intensio_logo.png';
import { COLORS } from '../colors';
import { get } from '../utils';
import { parseHtmlForPDF } from '../utils/htmParser';
import {
	isHeaderOnlySection,
	isSection,
	isSectionWithoutHeader,
	TranslatedToolMessages,
} from './ToolTemplate';
import { ToolBySlugQuery_prismicTool_data } from './__generated__/ToolBySlugQuery';

interface Props {
	tool: ToolBySlugQuery_prismicTool_data | null;
	messages: TranslatedToolMessages;
}

export const ToolTemplatePdf: FC<Props> = ({ tool, messages }) => {
	const name = get(tool?.name, 'text');

	return (
		<Document title={name} author="Intensio">
			<Page style={pdfStyles.body}>
				<View style={{ paddingBottom: 20 }}>
					<Image style={pdfStyles.logo} src={logo} />
				</View>

				<View style={pdfStyles.headerSection}>
					<Text style={pdfStyles.h1}>
						{messages.tool}: {name}
					</Text>
				</View>

				<View
					style={{
						flexDirection: 'row',
					}}>
					<View style={[pdfStyles.text, { flexGrow: 1, width: '55%', paddingRight: 15 }]}>
						{parseHtmlForPDF(tool?.description?.html)}
					</View>
					{tool?.requiredMaterial?.html?.length ? (
						<View style={{ width: 200 }}>
							<View style={pdfStyles.materialCard}>
								<Text style={[pdfStyles.h3, { marginLeft: 5 }]}>
									{messages.material}
								</Text>
								<View style={pdfStyles.text}>
									{parseHtmlForPDF(tool?.requiredMaterial?.html)}
								</View>
							</View>
						</View>
					) : null}
				</View>

				{tool?.body?.map((section, itemIndex) => {
					if (!section) {
						return null;
					}

					if (isSection(section)) {
						return (
							<View
								key={section.primary?.section_header?.text as string}
								style={[pdfStyles.section, { marginTop: itemIndex !== 0 ? 20 : 0 }]}
								wrap={false}>
								<Text style={pdfStyles.h2}>
									{section.primary?.section_header?.text}
								</Text>
								<View style={pdfStyles.text}>
									{parseHtmlForPDF(section.primary?.section_content?.html)}
								</View>
							</View>
						);
					}

					if (isSectionWithoutHeader(section)) {
						return (
							<View key={itemIndex} style={pdfStyles.section} wrap={false}>
								<View style={pdfStyles.text}>
									{parseHtmlForPDF(section.primary?.section_content?.html)}
								</View>
							</View>
						);
					}

					if (isHeaderOnlySection(section)) {
						return (
							<Text
								key={section.primary?.section_header?.text as string}
								style={[pdfStyles.h2, { marginTop: 20 }]}>
								{section.primary?.section_header?.text}
							</Text>
						);
					}

					return null;
				})}

				{tool?.template?.raw?.kind === 'image' && tool?.template?.url ? (
					<View break>
						<Text style={pdfStyles.h2}>{messages.template}</Text>
						<Image
							src={tool?.template?.url}
							style={{ maxWidth: tool?.template?.raw.width }}
						/>
					</View>
				) : null}

				<Text style={pdfStyles.copy} fixed>
					Copyright Intensio {new Date().getFullYear()} - All rights reserved -{' '}
					<Link style={pdfStyles.link} src="https://www.intensio.be">
						www.intensio.be
					</Link>
				</Text>

				<Text
					style={pdfStyles.pageNumber}
					render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
					fixed
				/>
			</Page>
		</Document>
	);
};

Font.register({
	family: 'Roboto',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc9AMP6lQ.ttf',
			fontWeight: 500,
		},
	],
});

Font.register({
	family: 'Open Sans',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0ef8pkAg.ttf',
			fontWeight: 400,
		},
		{
			src: 'https://fonts.gstatic.com/s/opensans/v17/mem6YaGs126MiZpBA-UFUK0ZdchGAK6b.ttf',
			fontWeight: 400,
			fontStyle: 'italic',
		},
		{
			src: 'https://fonts.gstatic.com/s/opensans/v17/mem5YaGs126MiZpBA-UN7rgOUuhsKKSTjw.ttf',
			fontWeight: 700,
		},
	],
});

Font.registerHyphenationCallback(word => [word]);

export const pdfStyles = StyleSheet.create({
	headerSection: {
		marginTop: 15,
		marginBottom: 20,
	},
	section: {
		marginBottom: 5,
	},
	body: {
		paddingTop: 35,
		paddingBottom: 55,
		paddingHorizontal: 35,
	},
	logo: {
		width: 125,
	},
	h1: {
		fontSize: 19,
		marginBottom: 5,
		color: COLORS.secondary,
		fontWeight: 500,
		fontFamily: 'Roboto',
	},
	h2: {
		fontSize: 14,
		marginBottom: 10,
		color: COLORS.secondary,
		fontWeight: 500,
		fontFamily: 'Roboto',
	},
	h3: {
		fontSize: 12,
		marginBottom: 6,
		marginTop: 10,
		color: COLORS.secondary,
		fontWeight: 500,
		fontFamily: 'Roboto',
	},
	h4: {
		fontSize: 11,
		marginBottom: 4,
		marginTop: 8,
		color: COLORS.secondary,
		fontWeight: 500,
		fontFamily: 'Roboto',
	},
	h5: {
		fontSize: 10,
		marginBottom: 3,
		marginTop: 6,
		color: COLORS.secondary,
		fontWeight: 500,
		fontFamily: 'Roboto',
	},
	text: {
		fontSize: 10,
		color: COLORS['secondary-600'],
		fontFamily: 'Open Sans',
		lineHeight: 1.6,
		textAlign: 'justify',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 9,
		bottom: 30,
		right: 35,
		color: 'grey',
	},
	copy: {
		position: 'absolute',
		fontSize: 9,
		bottom: 30,
		left: 35,
		color: 'grey',
	},
	link: {
		color: COLORS['primary'],
	},
	materialCard: {
		backgroundColor: COLORS['secondary-100'],
		paddingHorizontal: 14,
		paddingBottom: 5,
		paddingTop: 3,
		marginBottom: 15,
		borderRadius: 6,
	},
	strong: {
		fontWeight: 'bold',
	},
	em: {
		fontStyle: 'italic',
	},
});
