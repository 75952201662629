import { Text } from '@react-pdf/renderer';
import { DomElement } from 'domhandler';
import htmlToDOM from 'html-dom-parser';
import React, { ReactNode } from 'react';
import { pdfStyles } from '../../templates/ToolTemplatePdf';
import { UnorderedList, UnorderedListItem } from './components/Ul';

type NodeMap = {
	[key: string]: (node: DomElement) => ReactNode;
};

const NodeMapper: NodeMap = {
	p: ({ children }) => <Text>{mapNodes(children)}</Text>,
	ul: ({ children }) => <UnorderedList>{mapNodes(children)}</UnorderedList>,
	li: ({ children }) => <UnorderedListItem>{mapNodes(children)}</UnorderedListItem>,
	text: ({ data }) => <Text debug>{data}</Text>,
	h3: ({ children }) => <Text style={pdfStyles.h3}>{mapNodes(children)}</Text>,
	h4: ({ children }) => <Text style={pdfStyles.h3}>{mapNodes(children)}</Text>,
	h5: ({ children }) => <Text style={pdfStyles.h3}>{mapNodes(children)}</Text>,
	strong: ({ children }) => <Text style={pdfStyles.strong}>{mapNodes(children)}</Text>,
	em: ({ children }) => (
		<Text style={pdfStyles.em} debug>
			{mapNodes(children)}
		</Text>
	),
};

export const parseHtmlForPDF = (html?: string | null) => {
	if (!html || !html.length) return null;

	const nodes = htmlToDOM(html);

	return mapNodes(nodes);
};

export const mapNodes = (nodes?: DomElement | DomElement[]) => {
	if (!nodes) {
		return null;
	}

	const toConvert = Array.isArray(nodes) ? nodes : [nodes];

	return toConvert.map(node => {
		const type = node.name || node.type;

		if (!type) {
			return null;
		}

		const resolver = NodeMapper[type];

		if (!resolver) {
			console.warn(`${type}:: not implemented!`);
			return null;
		}

		return resolver(node);
	});
};
