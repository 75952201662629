import React from 'react';
import ToolTemplate from '../templates/ToolTemplate';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { isBrowser } from '../utils';

export const UnpublishedPage = (props: any) => {
	if (!isBrowser) {
		return null;
	}

	return (
		<ErrorBoundary>
			<ToolTemplate {...props} />
		</ErrorBoundary>
	);
};

export default UnpublishedPage;
