import { View, StyleSheet, Text } from '@react-pdf/renderer';
import React, { FC } from 'react';
import { pdfStyles } from '../../../templates/ToolTemplatePdf';

export const UnorderedList: FC = ({ children }) => {
	return <View style={styles.list}>{children}</View>;
};

export const UnorderedListItem: FC = ({ children }) => {
	return (
		<View style={styles.listItem}>
			<Text style={pdfStyles.text}>
				• &nbsp;{children}
			</Text>
		</View>
	);
};

const styles = StyleSheet.create({
	list: {
		marginTop: 8,
		marginLeft: 6,
	},
	listItem: {
		marginBottom: 4,
	},
});
